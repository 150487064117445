import { NgModule, Optional, SkipSelf } from '@angular/core';

import { SettingsService } from './settings/settings.service';
import { ThemesService } from './themes/themes.service';
import { TranslatorService } from './translator/translator.service';
import { throwIfAlreadyLoaded } from './module-import-guard';

import { MenuService } from './menu/menu.service';
// Auth
import { AuthService } from './auth/auth.service';
import { AuthGuardService as AuthGuard } from './auth/auth.guard';
import { AppSetupService } from './auth/app.init.setup';
import { AuthAppService } from './auth/auth.app';
import { LogService } from './log/log.service';
import { EntityLogService } from './carecloud/entity.log.service';
import { CCService } from './carecloud/carecloud.api.service';
import { JsonClientFactoryService } from './carecloud/JsonClientFactory.service';
import { TranslationPipe } from './pipe/translationPipe';
import { FormatNamePipe } from './pipe/formatName.pipe';
import { FormatPhonePipe } from './pipe/formatPhone.pipe';
import { FormatInternationalPhonePipe } from './pipe/formatInternationalPhone.pipe';
import { TranalateService } from './models/translate.service';
import { GatewayService } from './carecloud/gateway.service';
import { EntityService } from './services/entityService';
import { EntityDataService } from './services/entityDataService';
import { EntityFieldService } from './services/entityFieldService';
import { ParentEntityService } from './services/parentEntityService';
import { WorkspaceGuardService } from './auth/workspace.guard.service';
import { ReportService } from './services/reportService';
import { ReportCacheService, EntityCacheService } from './services/cacheService';
import { PendingChangesGuard } from './auth/pending.changes.guard';
import { FocusService } from './services/focusService';
@NgModule({
  imports: [],
  providers: [
    SettingsService,
    ThemesService,
    TranslatorService,
    TranslationPipe,
    FormatNamePipe,
    FormatPhonePipe,
    FormatInternationalPhonePipe,
    MenuService,
    // Auth
    AuthService,
    AuthGuard,
    AppSetupService,
    AuthAppService,
    LogService,
    EntityLogService,
    CCService,
    JsonClientFactoryService,
    WorkspaceGuardService,
    TranalateService,
    GatewayService,
    EntityService,
    EntityDataService,
    EntityFieldService,
    ReportService,
    ParentEntityService,
    ReportCacheService,
    EntityCacheService,
    PendingChangesGuard,
    FocusService,
  ],
  declarations: [],
  exports: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
