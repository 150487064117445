/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.0
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import './vendor';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { createGaSession } from '@shared/utils';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare global {
  interface Window {
    [key: string]: any;
  }
}

// 设置默认的 WKcode，当用户没有输入CustomerCode // 复制至 index.html
function setDefaultWkCode() {
  window['wkCode'] = '';
  let wk = window['wkCode'];
  const host = location.hostname.toLowerCase();
  const hostList = [
    'localhost',
    'shtest-carewait.mcttechnology.cn',
    'shtest-carewait-stanford.mcttechnology.cn',
    'ustest-stanford.carecloud.io',
    'ustest-carewait-v2.carecloud.io',
    'childcare-uat.stanford.edu',
    'childcare.stanford.edu',
    'stanford.carecloud.io',
    'carewait-su.carecloud.io',
  ];
  if (hostList.indexOf(host) > -1) {
    const urlPath = location.pathname;
    const urlPathList = urlPath && urlPath !== '/' ? urlPath.split('/') : [];
    if (urlPathList.length > 0) {
      wk = urlPathList[1];
    } else {
      wk = 'Stanford';
      if (
        host === 'ustest-stanford.carecloud.io' ||
        host === 'ustest-carewait-v2.carecloud.io' ||
        host === 'childcare-uat.stanford.edu'
      ) {
        wk = 'StanfordTest';
      }
    }
    wk = wk ? wk : 'Stanford';
    window['wkCode'] = wk;
    localStorage.setItem('mCache_current_wkCode', wk);
    if (!urlPath || urlPath === '/' || urlPath === '//' || urlPath === '///') {
      const url = '/' + wk + '/family';
      location.href = url;
    }
  } else {
    localStorage.removeItem('mCache_current_wkCode');
  }
}

setDefaultWkCode();

if (environment.production) {
  enableProdMode();
  createGaSession(environment.googleAnalytics);
}

const p = platformBrowserDynamic().bootstrapModule(AppModule);
p.then(() => {
  // tslint:disable-next-line:no-unused-expression
  (<any>window).appBootstrap && (<any>window).appBootstrap();
}).catch(err => console.error(err));
