import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';

// auth
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslationPipe } from './core/pipe/translationPipe';
import { CommunicationService } from './shared/utils/communication.service';
import { AppSetupService } from './core/auth/app.init.setup';

import { RouteReuseStrategy } from '@angular/router';
import { MyRouteReuseStrategy } from './RouteReuseStrategy';
// import { UserIdleModule } from 'angular-user-idle';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { PaginationModule } from './shared/components/pagination/pagination.module';

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function SetupApp(setup: AppSetupService) {
  return () => setup.initliaze();
}

@NgModule({
  declarations: [AppComponent, TranslationPipe],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    PaginationModule,
    RoutesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LoggerModule.forRoot({
      serverLoggingUrl: '',
      level: NgxLoggerLevel.ERROR,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
    NgxPermissionsModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    // UserIdleModule.forRoot({idle: 600, timeout: 60, ping: 120}) // 配置信息，移只 app.component.ts 中读取customer 设置后进行初始化
  ],
  providers: [
    AppSetupService,
    {
      provide: APP_INITIALIZER,
      useFactory: SetupApp,
      deps: [AppSetupService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: MyRouteReuseStrategy,
    },
    CommunicationService,
    provideNzI18n(en_US),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
