<router-outlet></router-outlet>

<dx-popup
  class="popup dx-popup-focus"
  [width]="isMobile ? '90%' : '550'"
  height="auto"
  (onInitialized)="stopEscKey($event)"
  [showCloseButton]="false"
  [showTitle]="true"
  title="Warning"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [(visible)]="isWarningLogOut"
>
  <div *dxTemplate="let data of 'content'">
    <div class="pt-2 pb-2 text-center" style="line-height: 2em; font-size: 1.1em" id="timeoutId">
      <!-- <span *ngIf="isTimeOut">
                Because you have been inactive for <b>{{idleTime}} minutes</b>,<br> the system has automatically logged out!
            </span> -->
      <span>
        <!-- System will log out due to inactive for <b>{{idleTime}} minutes</b>. <br>
                 <ng-container *ngIf="countDownLogout>0">
                 Logout countdown: {{countDownLogout}}
                </ng-container> -->

        You will be automatically logged out in {{ countDownLogout }} seconds due to no activity.<br />
        Click "Cancel" to remain logged in.
      </span>
    </div>
    <div class="">
      <!-- <dx-toolbar #formToolbar>
        <dxi-item
          widget="dxButton"
          location="after"
          toolbar="bottom"
          [options]="{
            text: 'OK',
            type: 'default',
            stylingMode: 'contained',
            onClick: goLoginPage,
            visible: isTimeOut
          }"
        >
        </dxi-item>
        <dxi-item
          widget="dxButton"
          location="after"
          toolbar="bottom"
          [options]="{
            text: 'Cancel',
            type: 'default',
            stylingMode: 'contained',
            onClick: continue,
            visible: !isTimeOut
          }"
        >
        </dxi-item>
      </dx-toolbar> -->

      <dx-button class="float-right ml-1 mt-1" stylingMode="contained" text="Cancel" type="default" (onClick)="continue($event)"
        [visible]="!isTimeOut">
      </dx-button>
      
      <dx-button class="float-right mt-1" stylingMode="contained" text="OK" type="default" (onClick)="goLoginPage($event)"
        [visible]="isTimeOut">
      </dx-button>

    

    </div>
  </div>
</dx-popup>
