import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { Router, CanActivate } from '@angular/router';

import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private apikey = environment.apigateway_key;
  // private host = environment.apigateway_url;

  constructor(public auth: AuthService, public router: Router) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(req);
    // return next.handle(req);

    // Get the auth header from the service.

    // if ( !req.url.startsWith('http') ) {
    //   baseurl = this.host + req.url;
    // }
    // console.log('call ' + baseurl + ' ?code=' + accesscode);

    // const accesscode =  this.auth.getToken();
    // const baseurl = req.url;
    // const authReq = req.clone({
    //   // url: baseurl,
    //   headers: req.headers
    //     .set('X-CC-AppId', this.apikey ? this.apikey : '')
    //     .set('X-CC-AccessCode', accesscode ? accesscode : '')
    //     .set('Accept', 'application/json')
    // });
    // return next.handle(authReq);
    // console.log(authReq);

    return next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          // console.log(event);
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // redirect to the login route
              this.router.navigate(['/home/login']);
              // or show a modal
            }
          }
        },
      }),
    );
  }
}
