import { Routes, RouterModule, ActivatedRouteSnapshot, RouteReuseStrategy } from '@angular/router';

export class MyRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  store(route: ActivatedRouteSnapshot, handle: {}): void {}
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  retrieve(route: ActivatedRouteSnapshot): {} {
    return null;
  }

  shouldReuseRoute(next: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    const path = next.routeConfig && (next.routeConfig as any).path;
    // service route
    const regexService = /waitlist+\/service|entity|report|viewer|importfile|systemSetting\/*/;
    // const regexService = /workspace\/[.]+\/service|entity\/[:-a-zA-Z0-9.]+/;
    const spath = regexService.test(path);
    return next.routeConfig === current.routeConfig && !(path && spath);
  }
}
