import { checkIsMobile } from '@shared/utils';
import Tabs from 'devextreme/ui/tabs';
import Popup from 'devextreme/ui/popup';
import DataGrid from 'devextreme/ui/data_grid';

export function initDevGlobalConfig() {
  Tabs.defaultOptions({
    options: {
      scrollingEnabled: checkIsMobile(),
    },
  });
  Popup.defaultOptions({
    options: { showCloseButton: true, copyRootClassesToWrapper: true },
  });

  DataGrid.defaultOptions({
    options: { columnChooser: {height:350} },
  });
}
