import { Component, HostBinding, OnInit } from '@angular/core';
import { initDevGlobalConfig } from '@core/config';
declare var $: any;

import { SettingsService } from './core/settings/settings.service';
import { AuthService } from './core/auth/auth.service';
import { NotifyService } from './shared/message/notify.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToolService } from './shared/utils/tool.service';
import { JsonClientFactoryService } from './core/carecloud/JsonClientFactory.service';
import { JsonServiceClient } from '@servicestack/client';
import { GetWorkspaceSettingRequest } from './core/models/admin.users.api';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { EntityCacheService } from './core/services/cacheService';
import config from "devextreme/core/config";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  client: JsonServiceClient;
  isInitIdle: any;
  isTimeOut: boolean = false;
  isActive: boolean;
  countDownLogout: number;
  countDownTimer: number;
  delayAutoTime: number;

  @HostBinding('class.layout-fixed') get isFixed() {
    return this.settings.getLayoutSetting('isFixed');
  }
  @HostBinding('class.aside-collapsed') get isCollapsed() {
    return this.settings.getLayoutSetting('isCollapsed');
  }
  @HostBinding('class.layout-boxed') get isBoxed() {
    return this.settings.getLayoutSetting('isBoxed');
  }
  @HostBinding('class.layout-fs') get useFullLayout() {
    return this.settings.getLayoutSetting('useFullLayout');
  }
  @HostBinding('class.hidden-footer') get hiddenFooter() {
    return this.settings.getLayoutSetting('hiddenFooter');
  }
  @HostBinding('class.layout-h') get horizontal() {
    return this.settings.getLayoutSetting('horizontal');
  }
  @HostBinding('class.aside-float') get isFloat() {
    return this.settings.getLayoutSetting('isFloat');
  }
  @HostBinding('class.offsidebar-open') get offsidebarOpen() {
    return this.settings.getLayoutSetting('offsidebarOpen');
  }
  @HostBinding('class.aside-toggled') get asideToggled() {
    return this.settings.getLayoutSetting('asideToggled');
  }
  @HostBinding('class.aside-collapsed-text') get isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  isWarningLogOut = false;
  wkCode = '';
  idleTime = 15;

  idleState = 0; // 'Not started.'
  timedOut = false;
  lastPing?: Date = null;
  peddingTime = 30;
  isMobile: Boolean;
  constructor(
    public settings: SettingsService,
    private auth: AuthService,
    private message: NotifyService,
    public route: ActivatedRoute,
    // private userIdle: UserIdleService,
    private factory: JsonClientFactoryService,
    private idle: Idle,
    private keepalive: Keepalive,
    private cacheSerive: EntityCacheService,
  ) {
    this.setWkCode();
    this.isMobile = ToolService.isMobile();
    this.client = this.factory.MctCareWaitClient;
    this.logOut = this.logOut.bind(this);
    this.continue = this.continue.bind(this);
    this.goLoginPage = this.goLoginPage.bind(this);

    // config({ 
    //   editorStylingMode: 'underlined' // or 'outlined' | 'underlined'| 'filled'
    // })
  }

  //#region idle dialog UI code

  reset() {
    // this.isActive = true;
    // this.idle.watch();
    // this.idleState = 1; // 'Started.';
    // this.timedOut = false;
  }

  setWkCode() {
    let wk = '';
    const rt = this.getLastRoute(this.route); // this.route.snapshot.params.wkcode ? this.route.snapshot.params.wkcode : this.route.firstChild.snapshot.params.wkcode;
    if (rt && rt.snapshot && rt.snapshot.params && rt.snapshot.params.wkcode) {
      wk = rt.snapshot.params.wkcode;
    }
    if (!wk) {
      wk = location.pathname.split('/')[1];
    }
    this.wkCode = wk;
  }

  getLastRoute(route) {
    if (route.firstChild) {
      return this.getLastRoute(route.firstChild);
    } else {
      return route;
    }
  }

  stopEscKey(e: any) {
    e.component.registerKeyHandler('escape', function (arg) {
      arg.stopPropagation();
    });
  }

  goLoginPage() {
    // this.auth.onlyToLogin(this.wkCode);
    location.reload();
  }

  logOut(isForce = false) {
    const vm = this;
    if (this.countDownLogout <= 0 || isForce) {
      if (!isForce) {
        vm.isWarningLogOut = true;
        vm.isTimeOut = true;
        sessionStorage.setItem('idle_timeout_logout', this.idleTime + '');
      }
      vm.auth.logout(vm.wkCode, false);
      vm.idle.stop();
    }
    // if (isForce) {
    //     vm.auth.logout(vm.wkCode, false);
    //     sessionStorage.setItem('idle_timeout_logout', this.idleTime + '');
    //     vm.idle.stop();
    // }
  }

  continue() {
    localStorage.setItem('cancel_timeout_idle', '1');
    // this.reset();
    this.isWarningLogOut = false;
    this.isTimeOut = false;
    clearInterval(this.countDownTimer);
    setTimeout(() => {
      localStorage.setItem('cancel_timeout_idle', '0');
    }, 1000);
  }

  async getSysIdleTime(customerId: number) {
    let cachekey = 'GetWorkspaceSettingRequest_AutoLogoutTime';
    let response = this.cacheSerive.getCacheData(cachekey);
    if (!response) {
      const postData = new GetWorkspaceSettingRequest();
      postData.SettingKey = 'AutoLogoutTime';
      const client = this.factory.getMctCarewaitV2ApiClient();
      response = await client.get(postData);
      this.cacheSerive.setCacheData(cachekey, response);
    }
    if (response.IsSuccess) {
      this.idleTime =
        response.Data && response.Data.SettingValue && response.Data.SettingValue * 1 > 0
          ? response.Data.SettingValue * 1
          : this.idleTime;
      // this.idleTime = 0.1;
      this.startIdle(this.idleTime);
    }
  }

  checkLoginUser() {
    const sessionKey = this.auth['userTokenKey'];
    const session = localStorage.getItem(sessionKey);
    if (session) {
      return true;
    }
    return false;
  }

  countDownNumber() {
    this.countDownLogout = this.peddingTime;
    if (this.countDownTimer) {
      clearInterval(this.countDownTimer);
    }
    this.countDownTimer = setInterval(x => {
      this.countDownLogout--;
      const isIdle = localStorage.getItem('cancel_timeout_idle');
      if (isIdle === '0') {
        if (this.countDownLogout < 1) {
          clearInterval(this.countDownTimer);
          this.logOut();
        }
      }
    }, 1000);
  }

  openLogoutDialog(count) {
    // debugger;
    if (this.checkLoginUser()) {
      if (count === this.peddingTime) {
        localStorage.setItem('cancel_timeout_idle', '0');
        this.isWarningLogOut = true;
        // this.isActive = false;
        this.countDownNumber();
      }
    } else {
      // this.reset();
      // console.log('==== go logoinPage method here openLogoutDialog');
      this.goLoginPage();
    }
  }

  resetTokenTime(fun) {
    const vm = this;
    if (fun.timeoutId) {
      window.clearTimeout(fun.timeoutId);
    }
    fun.timeoutId = window.setTimeout(function () {
      fun(vm);
      fun.timeoutId = null;
    }, 1000);
  }

  delayAuthToken(vm) {
    // const now = moment().format('YYYY-MM-DD HH:mm:ss');
    // console.log('==== delayAuthToken', now);
    const token = vm.auth.getToken();
    if (token) {
      if (vm.delayAutoTime && vm.delayAutoTime > 0) {
        vm.auth.delayTokenTime(vm.delayAutoTime);
      } else {
        vm.auth.delayTokenTime();
      }
    } else {
      // console.log('==== delayAuthToken: ');
      vm.logOut(true);
    }
  }

  startIdle(idleTime) {
    const vm = this;
    let t = idleTime * 60; // 转换空闲时间配置：分钟数为秒数
    vm.delayAutoTime = t + 60;
    // console.log('==== set delayAuthToken minites: ', vm.delayAutoTime);
    vm.delayAuthToken(vm);
    // t = 15;
    t = t > this.peddingTime ? t : this.peddingTime; // idle time 不能小于pedding time
    // console.log('==== start to watch idle time', idleTime, t);
    // sets an idle timeout of 5 seconds, for testing purposes.
    vm.idle.setIdle(t);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    vm.idle.setTimeout(this.peddingTime); // inactive 倒计时设置
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    vm.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // 设置监听事件

    vm.idle.onIdleEnd.subscribe(() => {
      // this.isActive = true;
      // console.log('==== onIdleEnd', idleTime, t);
    });
    vm.idle.onTimeout.subscribe(() => {
      // console.log('====vm.idle.onTimeout.subscribe()');
      vm.logOut();
    });
    vm.idle.onIdleStart.subscribe(() => {
      this.idleState = -1; // 'You\'ve gone idle!'
      // this.isTimeOut = true;
      // console.log('==== onIdleStart');
    });
    vm.idle.onTimeoutWarning.subscribe(countdown => {
      // this.idleState = 'You will time out in ' + countdown + ' seconds!'
      // console.log('==== onTimeoutWarning');
      vm.openLogoutDialog(countdown);
    });
    // sets the ping interval to 15 seconds
    vm.keepalive.interval(15);
    vm.keepalive.onPing.subscribe(() => {
      // this.idleState = 2;// 'No longer idle.';
      // console.log('==== before onPing.subscribe()');
      if (!this.checkLoginUser()) {
        // console.log('==== onPing to logout');
        vm.logOut();
      }
    });
    this.idle.onInterrupt.subscribe(x => {
      // console.log('==== onInterrup', moment().format('YYYY-MM-DD HH:mm:ss'), x, vm);
      vm.resetTokenTime(vm.delayAuthToken);
      setTimeout(() => {
        const isIdle = localStorage.getItem('cancel_timeout_idle');
        // console.log('==== onInterrup', isIdle, moment().format('YYYY-MM-DD HH:mm:ss'));
        if (isIdle === '1') {
          vm.continue();
          // console.log('==== localStorage cancel_timeout_idle', isIdle, x, moment().format('YYYY-MM-DD HH:mm:ss'));
        }
      }, 800);
    });
    this.idle.watch();
  }
  //#endregion

  ngOnInit() {
    initDevGlobalConfig();
    $(document).on('click', '[href="#"]', e => e.preventDefault());
    // this.auth.loginWithToken().then(u => console.log('auto login'));
    const vm = this;
    this.auth.currentUser.subscribe(x => {
      if (x && x.UserId > 0) {
        const isDev = ToolService.getEnvironment() === 'dev';
        if (true || !isDev) {
          if (!vm.isInitIdle) {
            vm.isInitIdle = true;
            setTimeout(() => {
              // console.log('==== settimgout to oboive token is null bug token: ', vm.auth.getToken() );
              vm.getSysIdleTime(x.CustomerId); // fix 首次登陆,Token还未写入cookie,造成的自动退出Bug
            }, 500);
          }
        }
      }
    });
  }
}
