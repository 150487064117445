import { AuthAppService } from './auth.app';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppSetupService {
  constructor(private auth: AuthAppService) {}

  public initliaze(): Promise<any> {
    return this.auth.getApiToken();
  }
}
